<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">{{ $t("Obligation report") }}</p>

    <div class="obligo-filter-block">
      <p class="mx-5"><b>{{ $t("Filter By Distributer") }}</b></p>
      <div class="d-flex obligation-filter-group">
        <div class="obligation-filter-form w-100">
          <label class="label">{{ $t("Distributer") }}</label>
          <input type="text" class="input" v-model="filter.distributor">
        </div>
        <div class="obligation-filter-form w-100">
          <label class="label">{{ $t("Business Name") }}</label>
          <input type="text" class="input" v-model="filter.bName">
        </div>
        <div class="obligation-filter-form w-100">
          <label class="label">{{ $t("Client No.") }}</label>
          <input type="text" class="input" v-model="filter.clientNo">
        </div>
        <div class="obligation-filter-form w-100">
          <label class="label">{{ $t("Agent tax") }}</label>
          <input type="text" class="input" v-model="filter.agent">
        </div>
        <div class="obligation-filter-form w-100">
          <label class="label">{{ $t("Mobile Phone") }}</label>
          <input type="text" class="input" v-model="filter.mobile_phone">
        </div>
        <div class="obligation-filter-form w-100">
          <label class="label">{{ $t("H.P. / Authorized Dealer") }}</label>
          <input type="text" class="input" v-model="filter.aDealer">
        </div>
      </div>
    </div>

    <button class="primary-btn obligo-filter_btn" @click="searchData()">
      {{ $t("Search") }}
    </button>

    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="filteredTableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
      >
      </v-data-table>

    </div>
    <div class="table-print-excel-part d-flex align-items-center">
      <div class="d-flex align-items-center" @click="printTableData">
        <img src="/assets/img/print.png" alt="" class="print-img hover-pointer mx-7">
        <label for="">{{ $t("For printing") }}</label>
      </div>
      <download-excel 
        :data="filteredTableData"
        :fields="excel_fields"
        worksheet="My Worksheet"
        name="obligo-report.xls"
      >
        <div class="d-flex align-items-center">
          <img src="/assets/img/excel.png" alt="" class="excel-img hover-pointer">
          <label for="">{{ $t("Export to Excel") }}</label>
        </div>
      </download-excel>
    </div>
    <loading :active.sync="loading" :can-cancel="false" :is-full-page="true"></loading>
  </main>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ObligoReport",
  computed: {
    ...mapState({
      businessData: (state) => state.seller_tourism_percentage.businessData,
      distributeData: (state) => state.seller_tourism_percentage.distributeData,
      loading: state => state.seller_tourism_percentage.loading,
    }),
    excel_fields () {
      let temp = {};
      temp[this.$t("Business name")] = "name";
      temp[this.$t("Client No.")] = "business_identifier";
      temp[this.$t("City")] = "full_address";
      temp[this.$t("Agent")] = "agent";
      temp[this.$t("Marketer / Distributor")] = "name";
      temp[this.$t("Payment Methods")] = "payment_method";
      temp[this.$t("Collection day")] = "collection_day";
      temp[this.$t("Line of credit")] = "frame";
      temp[this.$t("Temporary framework")] = "temp_frame";
      temp[this.$t("Current balance")] = "balance";
      temp[this.$t("Balance for utilization")] = "utilization";
      temp[this.$t("Missing documents")] = "missing_documents";
      return temp;
    }
  },
  async created() {
    if (this.distributeData == '') {
      await this.getDistributes();
    }
    this.createObligoData();
  },
  data() {
    return {
      search: "",
      filter: {
        distributor: "",
        bName: "",
        clientNo: "",
        agent: "",
        mobile_phone: "",
        aDealer: "",
      },
      headers: [
        {
          text: this.$t("Business name"),
          align: "center",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("Client No."),
          align: "center",
          sortable: true,
          value: "business_identifier"
        },
        {
          text: this.$t("City"),
          align: "center",
          sortable: true,
          value: "full_address"
        },
        {
          text: this.$t("Agent"),
          align: "center",
          sortable: true,
          value: "agent"
        },
        {
          text: this.$t("Marketer / Distributor"),
          align: "center",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("Payment Methods"),
          align: "center",
          sortable: true,
          value: "payment_method"
        },
        {
          text: this.$t("Collection day"),
          align: "center",
          sortable: true,
          value: "collection_day"
        },
        {
          text: this.$t("Line of credit"),
          align: "center",
          sortable: true,
          value: "frame"
        },
        {
          text: this.$t("Temporary framework"),
          align: "center",
          sortable: true,
          value: "temp_frame"
        },
        {
          text: this.$t("Current balance"),
          align: "center",
          sortable: true,
          value: "balance"
        },
        {
          text: this.$t("Balance for utilization"),
          align: "center",
          sortable: true,
          value: "utilization"
        },
        {
          text: this.$t("Missing documents"),
          align: "center",
          sortable: true,
          value: "missing_documents"
        },
      ],
      obligoData: [],
      filteredTableData: []
    }
  },
  methods: {
    ...mapActions('seller_tourism_percentage', {
      getDistributes: 'getDistributes',
      getBusiness: 'getBusiness'
    }),
    async createObligoData () {
      let tempObligoData = [];
      for (const key in this.distributeData) {
        if (this.distributeData[key]) {
          let distribute_code = this.distributeData[key].distributor_id;
          await this.getBusiness({ id: distribute_code });
          tempObligoData = tempObligoData.concat(this.businessData);
        }
      }
      const that = this;
      tempObligoData.forEach(el => {
        el.utilization = el.balance + el.temp_frame + el.frame;
        el.collection_day = that.createDaysString(el);
        el.full_address = that.createAddressString(el);
        el.distributor_name = that.getDistributorString(el.distributor_id);
      });
      this.obligoData = tempObligoData;
      this.filteredTableData = tempObligoData;
    },
    createDaysString (item) {
      let tempString = "";
      if (item.monday) {
        tempString += this.$t("A") + ",";
      }
      if (item.tuesday) {
        tempString += this.$t("B") + ",";
      }
      if (item.wednesday) {
        tempString += this.$t("C") + ",";
      }
      if (item.thursday) {
        tempString += this.$t("D") + ",";
      }
      if (item.friday) {
        tempString += this.$t("E") + ",";
      }
      if (item.saturday) {
        tempString += this.$t("F") + ",";
      }
      if (tempString.length != 0) {
        tempString = tempString.substring(0, tempString.length - 1);
      }
      return tempString;
    },
    createAddressString (item) {
      const fullAddress = `${item.street ?? ''} ${
        item.number ?? ''
      }, ${item.city ?? ''}, ${
        item.country ?? ''
      }`;

      return fullAddress;
    },
    getDistributorString (id) {
      const tempStr = this.distributeData.find(el => {
        return el.distributor_id == id;
      }).name;

      return tempStr;
    },
    printTableData() {
      printJS({
        printable: this.filteredTableData, 
        properties: [
          { field: 'name', displayName: this.$t("Business name") },
          { field: 'business_identifier', displayName: this.$t("Client No.") },
          { field: 'full_address', displayName: this.$t("City") },
          { field: 'agent', displayName: this.$t("Agent") },
          { field: 'name', displayName: this.$t("Marketer / Distributor") },
          { field: 'payment_method', displayName: this.$t("Payment Methods") },
          { field: 'collection_day', displayName: this.$t("Collection day") },
          { field: 'frame', displayName: this.$t("Line of credit") },
          { field: 'temp_frame', displayName: this.$t("Temporary framework") },
          { field: 'balance', displayName: this.$t("Current balance") },
          { field: 'utilization', displayName: this.$t("Balance for utilization") },
          { field: 'missing_documents', displayName: this.$t("Missing documents") }
        ],
        type: 'json',
        gridHeaderStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;',
        gridStyle: this.currentLang == 'en' ? 'direction: ltr; border: 1px solid #3971A5;' : 'direction: rtl; border: 1px solid #3971A5;'
      })
    },
    searchData () {
      let temp = [];
      this.obligoData.forEach(el => {
        if (
          (this.filter.distributor == '' || this.filter.distributor == el.distributor_name) && 
          (this.filter.bName == '' || this.filter.bName == el.name) && 
          (this.filter.clientNo == '' || this.filter.clientNo == el.business_identifier) && 
          // (this.filter.agent == '' || this.filter.agent == el.agent) && 
          (this.filter.mobile_phone == '' || this.filter.mobile_phone == el.phone_number) &&
          (this.filter.aDealer == '' || this.filter.aDealer == el.business_license_number) 
        ) {
          temp.push(el);
        }
      });
      this.filteredTableData = temp;
    }

  }
}
</script>

<style lang="scss" scoped>
.obligo-filter-block {
  border: 1px solid #707070;
  margin: 0 50px;
  @media (max-width: 767px) {
    margin: 0 20px;
  }
  .obligation-filter-group {
    margin-top: 20px;
    @media (max-width: 1200px) {
      flex-wrap: wrap;
    }
    .obligation-filter-form {
      @media (max-width: 1200px) {
        max-width: calc(50% - 20px);
      }
      @media (max-width: 575px) {
        max-width: 100%;
      }
      margin: 0 10px 20px 10px;
      width: 100%;
    }
  }
}
.obligo-filter_btn {
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 50px;
  max-width: 250px;
}
</style>